import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const Services = lazy(() => import('./pages/services'))
const Gg = lazy(() => import('./pages/gg'))
export default () => {
  return (
      <BrowserRouter basename="dig">
        <Switch>
        <Route path="/gg" exact  component={Gg}/>
          <Route path="/services" exact  component={Services}/>
          <Route exact path="/" component={Index}/>
        </Switch>
      </BrowserRouter>
  );
};
